@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@layer base {
  main {
    @apply flex flex-col items-start justify-between w-full min-h-screen;
  }

  h1 {
    @apply font-normal text-xana-txt-banner_blue text-4xl sm:text-3xl md:text-4xl lg:text-4xl xl:text-4xl 2xl:text-5xl  font-FreizeitTrial leading-[60px] tracking-tight;
  }

  h2 {
    @apply font-medium text-xana-txt-title text-3xl md:text-3xl lg:text-3xl xl:text-4xl 2xl:text-4xl font-FreizeitTrial leading-[40px]  md:leading-[40px] xl:leading-[50px] 2xl:leading-[50px] tracking-tight;
  }

  h3 {
    @apply font-medium text-xana-txt-title text-2xl md:text-xl lg:text-[30px] xl:text-[30px] font-FreizeitTrial leading-[60px] tracking-tight;
  }

  h4 {
    @apply font-semibold text-lg md:text-lg lg:text-xl text-xana-txt-title font-FreizeitTrial leading-[60px] tracking-tight;
  }

  h5 {
    @apply font-medium leading-8 text-base font-AvanttTrial;
  }

  h6 {
    @apply flex-1 text-lg font-medium  text-xana-txt-title font-AvanttTrial;
  }

  p {
    @apply font-light text-xana-txt-text text-base md:text-base lg:text-base xl:text-base font-AvanttTrial;
  }

  /*   aside {
    @apply mx-auto max-w-xl md:max-w-none;
  } */

  section {
    @apply py-8 w-full;
  }

  summary {
    @apply mt-0;
  }

  /*

  article {
    @apply flex;
  }

  details {
    @apply flex;
  }

  address {
    @apply flex;
  }

  blockquote {
    @apply flex;
  }

  cite {
    @apply flex;
  }

  time {
    @apply flex;
  } */
}

@layer components {
}

body {
  @apply bg-blue-400;
}

.my_border {
  @apply border border-black sm:border-purple-500 md:border-green-500 lg:border-blue-500 xl:border-red-500 2xl:border-yellow-500;
}

.container_long {
  @apply mx-auto px-3 sm:px-8 md:px-8 lg:px-8 xl:px-8 2xl:px-0 md:h-auto w-full sm:max-w-4xl md:max-w-[1024px] lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1280px];
}

.container_normal {
  @apply mx-auto px-3 sm:px-0 md:px-0 lg:px-0 xl:px-0 2xl:px-0 md:h-auto w-full sm:max-w-4xl md:max-w-[700px] lg:max-w-[795px] xl:max-w-[1000px] 2xl:max-w-[1000px];
}
